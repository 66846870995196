import { toggleLightbox } from './lightbox'

const dropdownButtons = document.querySelectorAll(
  '.dropdown-menu:not(.mobile) button',
)

if (dropdownButtons) {
  dropdownButtons.forEach(button => {
    button.addEventListener('click', function() {
      button.parentNode.classList.toggle('active')
      toggleLightbox()
    })
  })
}
