{
    const searchJobForm = document.querySelector('#form-search-jobs');
    if (searchJobForm) {
        const searchJobRegionField = document.querySelector(
            '#search_job_wojewodztwo',
        );
        const searchJobCityField = document.querySelector(
            '#search_job_miejscowosc',
        );

        const searchJobOffers = document.querySelector('#form-search-job-offers');

        const searchJobSortField = document.querySelector('#jobs-results-sorter');
        const loader = document.querySelector('#spinner-loader-job-offers');
        const resultCounter = document.querySelector('#results-count');


        const typeOffer=searchJobOffers.classList.value;

        searchJobRegionField.addEventListener('change', (e) => {
            fetch(`/api/operator/job-offer/cities-by-region/${typeOffer}/${e.target.value}`)
                .then((res) => res.json())
                .then((cities) => {
                    if (e.target.value) {
                        searchJobCityField.disabled = false;
                    }
                    searchJobCityField.value = '';
                    for (
                        let i = 0;
                        i < searchJobCityField.options.length;
                        i += 1
                    ) {
                        if (searchJobCityField.options[i].value) {
                            if (
                                !cities.includes(
                                    searchJobCityField.options[i].value,
                                )
                            ) {
                                searchJobCityField.options[i].classList.add(
                                    'd-none',
                                );
                            } else {
                                searchJobCityField.options[i].classList.remove(
                                    'd-none',
                                );
                            }
                        }
                    }
                });
        });

        const offerToHtml = (offers) => {
            if (!offers) {
                return ['<h5 class=\'text-center\'>Brak ofert pracy</h5>'];
            }

            return offers.map((offer) => `<div id="job-offer-item-container2" class="job-offer-item-container">
                <div class="accordion pimcore_wysiwyg accordion-flush accordionwithborders job-offer-wrapper px-0 mx-0 mt-0">
                    <div class="job-offer-simple-item mb-sm-3 ps-4 row align-items-center">
                        <div class="job-offer-icon d-inline-flex col-3"></div>
                        <div class="col-9">
                            <h2 class="job-offer-title ms-0 ps-0">
                                <button class="job-item-button py-4 rounded-0 ps-0 mb-0" type="button" aria-expanded="true">
                                    <a class="w-100" href="/operator.enea.pl/ospolce/karieraweneioperator/pracaogloszenie/${offer.id}" target="_blank"><p>${offer.title}</p></a>
                                </button>
                            </h2>
                            <div class="row mb-4">
                                <div class="col-6"><span class="job-offer-date">Data publikacji: ${offer.datapublikacji}</span></div>
                                <div class="col-6">Miejscowość: <strong>${offer.city}</strong></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`);
        };

        const selectOffers = () => {
            loader.classList.remove('d-none');
            loader.classList.add('d-flex');
            searchJobOffers.classList.add('d-none');

            fetch(
                `/api/operator/job-offer/search/${typeOffer}/${searchJobSortField.value ? searchJobSortField.value : 'EMPTY'}/${searchJobRegionField.value ? searchJobRegionField.value : 'EMPTY'}/${searchJobCityField.value}`,
            )
                .then((res) => res.json())
                .then((offers) => {
                    resultCounter.innerText = offers.length;
                    loader.classList.remove('d-flex');
                    loader.classList.add('d-none');
                    searchJobOffers.classList.remove('d-none');
                    searchJobOffers.innerHTML = offerToHtml(offers).join(' ');
                });
        };

        searchJobForm.addEventListener('submit', (e) => {
            e.preventDefault();
            selectOffers();
        });

        searchJobSortField.addEventListener('change', () => {
            selectOffers();
        });

        selectOffers();
    }
}
