const numbers = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/
const letters = /^[a-zA-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż]+$/
const companyName = /^[a-zA-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż0-9 \-"']+$/
const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const fullName =
  '^[a-zA-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż][a-zA-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż-]+\\s[a-zA-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż][a-zA-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż-]+$'
const postCode = '\\d{2}-\\d{3}$'
const phone = '^\\d{9}$'
const homeAddress = /^[0-9]+[a-zA-Z]?\x20*\/?\x20*[0-9]*[a-zA-Z]?$/
const accountNumber = /^[a-zA-Z0-9]{15,32}$/

// Validatior Functions
module.exports = {
  companyName: input => !new RegExp(companyName, 'gi').test(input.value.trim()),

  letters: input => !new RegExp(letters, 'g').test(input.value.trim()),

  numbers: input => !new RegExp(numbers, 'g').test(input.value.trim()),

  accountNumber: input =>
    !new RegExp(accountNumber, 'g').test(input.value.trim()),

  emailValidator: input => !new RegExp(email, 'g').test(input.value.trim()),

  minLength: input => {
    let min = input.getAttribute('minlength')
    let regex = new RegExp('^.{' + min + ',}$', 'g')
    return input.value.trim().search(regex) === 0 ? false : true
  },

  fullNameValidator: input =>
    !new RegExp(fullName, 'gi').test(input.value.trim()),

  postCodeValidator: input =>
    !new RegExp(postCode, 'g').test(input.value.trim()),

  phone: input => !new RegExp(phone, 'g').test(input.value.trim()),

  peselValidator: input => {
    let pesel = input.value
    if (typeof pesel !== 'string') return false
    let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
    let sum = 0
    let controlNumber = parseInt(pesel.substring(10, 11))
    for (let i = 0; i < weight.length; i++) {
      sum += parseInt(pesel.substring(i, i + 1)) * weight[i]
    }
    sum = sum % 10
    return !(10 - sum === controlNumber)
  },

  nipValidator: input => {
    const checkIsNipValid = nip => {
      if (typeof nip !== 'string') {
        return false
      }

      const nipWithoutDashes = nip.replace(/-/g, '')
      const reg = /^[0-9]{10}$/
      if (reg.test(nipWithoutDashes) === false) {
        return false
      } else {
        const dig = ('' + nipWithoutDashes).split('')
        const control =
          (6 * parseInt(dig[0], 10) +
            5 * parseInt(dig[1], 10) +
            7 * parseInt(dig[2], 10) +
            2 * parseInt(dig[3], 10) +
            3 * parseInt(dig[4], 10) +
            4 * parseInt(dig[5], 10) +
            5 * parseInt(dig[6], 10) +
            6 * parseInt(dig[7], 10) +
            7 * parseInt(dig[8], 10)) %
          11
        if (parseInt(dig[9], 10) === control) {
          return true
        } else {
          return false
        }
      }
    }

    return !checkIsNipValid(input.value.trim())
  },

  krsValidator: input => {
    let regex = !new RegExp(numbers, 'g').test(input.value.trim())
    let length = input.value.trim().length !== 10

    return regex || length
  },

  regonValidator: input => {
    const checkIsRegonValid = regon => {
      let regex = /^(?:\d{9}|\d{14})$/

      if (!regex.test(regon)) {
        return false
      }

      const checkSum = (number, weight) => {
        let max = number.length - 1
        let sum = 0

        for (let i = 0; i < max; i++) {
          let n = parseInt(number[i], 15)
          sum += n * weight[i]
        }

        let resultSum = sum % 11 !== 10 ? sum % 11 : 0
        let lastDigit = parseInt(number.slice(-1), 10)

        return resultSum === lastDigit
      }

      if (regon.length === 9) {
        return checkSum(regon, [8, 9, 2, 3, 4, 5, 6, 7])
      } else if (regon.length === 14) {
        return checkSum(regon, [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8])
      } else {
        return (
          checkSum(regon.slice(0, 9), [8, 9, 2, 3, 4, 5, 6, 7]) &&
          checkSum(regon, [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8])
        )
      }
    }

    return !checkIsRegonValid(input.value.trim())
  },

  homeAddressValidator: input =>
    !new RegExp(homeAddress, 'g').test(input.value.trim()),

  yearBetweenValidator: (input, yearFrom) => {
    let yearTo = new Date().getFullYear()
    return !(yearFrom <= input.value.trim() && yearTo >= input.value.trim())
  },
}
