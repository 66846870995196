const popup = document.querySelector('.popup')
const popupClose = document.querySelector('.popup-close')
const lightbox = document.querySelector('.lightbox')

if (popup) {
  const dataDuration = popup.getAttribute('data-duration')
  const popupId = popup.getAttribute('data-id')
  const closeTimeFlag = 'closeTime-' + popupId
  const getCloseTime = localStorage.getItem(closeTimeFlag)

  if (dataDuration) {
    const checkTime = Date.now()
    const duration = (checkTime - getCloseTime) / 1000

    if (duration > dataDuration) {
      localStorage.removeItem(closeTimeFlag)
      showPopup()
    }
  }

  popupClose.addEventListener('click', e => {
    e.preventDefault()
    hidePopup()

    if (dataDuration) {
      const closeTime = Date.now()

      localStorage.removeItem(closeTimeFlag)
      localStorage.setItem(closeTimeFlag, closeTime)
    }
  })

  function isPopupClosed() {
    let popupInLocalStorage = false
    for (var i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i)
      popupInLocalStorage = key.indexOf('closeTime') === 0 ? true : false
    }
    return popupInLocalStorage
  }

  if (isPopupClosed()) {
    hidePopup()
  }

  function showPopup() {
    popup.classList.add('active')
    lightbox.classList.add('active')
  }

  function hidePopup() {
    popup.classList.remove('active')
    lightbox.classList.remove('active')
  }
}
