(function ($, document) {
    const resetBtnClassActive = (opvId) => {
        $(`#${opvId} .operator_navigation_nav .operator_navigation-button`).each(function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            }
        });
    }

    const resetPageClassActive = (opvId) => {
        $(`#${opvId} .operator_navigation_pages .operator_navigation_page`).each(function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            }
        });
    }

    $(document).ready(function () {
        $('.operator_navigation_container').each(function() {
            const opvId = $(this).attr('id');

            $(`#${opvId} .operator_navigation_nav .operator_navigation-button`).each(function () {
                $(this).click(function () {
                    const btnId = $(this).attr('id');

                    resetBtnClassActive(opvId);
                    resetPageClassActive(opvId);

                    $(this).addClass('active');
                    $(`#${opvId} .operator_navigation_pages #operator_navigation_${btnId}`).addClass('active');
                });
            });
        });
    });
}(jQuery));