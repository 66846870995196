const hide = () => {
  $('.css-loader').hide()
}

const show = () => {
  $('.css-loader').show()
}

exports.hide = hide
exports.show = show
