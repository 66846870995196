let clicks_button = 3;
let endValue = 3;
let button_img = document.getElementById("more_img");
if (button_img != null) {
    button_img.addEventListener("click", onClickMoreImg);
}

function onClickMoreImg() {
    /*   let maxValue = button_img.getAttribute('value');
       console.log(maxValue)*/
    let maxValue = button_img.querySelector('.count_image').innerHTML;

    clicks_button += 1;
    endValue += 3;
    for (let i = clicks_button; i <= endValue; i++) {

        if (document.getElementById("index" + i)!== null) {
            document.getElementById("index" + i).classList.remove('d-none');
            if (i >= maxValue) {
                button_img.classList.add('d-none');
                break;
            }
        }
    }
}