const hamburger = document.querySelector('.header.navigation .hamburger')
const mobileNav = document.querySelector('.mobile-nav')
const mobileNavClose = document.querySelectorAll('.mobile-nav .close')
const dropdownButtons = document.querySelectorAll(
  '.dropdown-menu.mobile button',
)
const slinky = $('.mobile-nav').slinky()

if (hamburger && mobileNav) {
  hamburger.addEventListener('click', () => {
    hamburger.classList.toggle('active')
    mobileNav.classList.toggle('active')
  })

  mobileNavClose.forEach(button => {
    button.addEventListener('click', function() {
      hamburger.classList.toggle('active')
      mobileNav.classList.toggle('active')
    })
  })

  if (dropdownButtons) {
    dropdownButtons.forEach(button => {
      button.addEventListener('click', function() {
        button.parentNode.classList.toggle('active')
      })
    })
  }
}
