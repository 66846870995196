let button = document.querySelector(".buttonsend");
const verification_mail1 = document.getElementsByClassName("verificationmail1");
const verification_mail2 = document.getElementsByClassName("verificationmail2");
const date_of_reading = document.getElementsByClassName("dateofreading");

if(verification_mail1.length > 0 && verification_mail2.length > 0){
    document.querySelector(".verificationmail1").addEventListener("keyup", checkEmail);
    document.querySelector(".verificationmail2").addEventListener("keyup", checkEmail);
}

function checkEmail() {
    let email1 = document.querySelector(".checkemail1");
    let email2 = document.querySelector(".checkemail2");
    if(email1.value != '' && email2.value != '')
    {
        if (email1.value != email2.value) {
            email2.classList.add("is-invalid")
            button.disabled = true;
        } else {
            email2.classList.remove("is-invalid")
            button.disabled = false;
        }
    }

}
if(date_of_reading.length > 0) {
    document.querySelector(".dateofreading").addEventListener("change", checkDateValue);
}
function checkDateValue() {
    let field = document.querySelector(".dateofreading");
    // let fieldSubmit = document.getElementById(fieldNameSubmit);
    var today = new Date();
    var dateValue = new Date(field.value)
    if (dateValue >= today) {
        // fieldSubmit.disabled = true
        button.disabled = true
        field.classList.add("is-invalid")
        field.parentNode.querySelectorAll('span').forEach(spanElement => {
            spanElement.remove();
        });
        const node = document.createElement("span");
        node.classList.add("invalid-feedback")
        node.classList.add("validation")

        const textnode = document.createTextNode("Data nie może być większa niż dzisiaj");
        node.appendChild(textnode);

        field.parentNode.appendChild(node);
    } else {
        field.classList.remove("is-invalid")
        // fieldSubmit.disabled = false
        button.disabled = false

    }
    // return (today)
}

const formClass = 'formbuilder';
const formElements = document.querySelectorAll(`form.${formClass}`);

formElements.forEach(form => {
    const formName = "div#" + form.name;
    /*console.log(form);
    console.log(document.querySelector(formName));
    document.querySelector(formName).removeAttribute('novalidate');*/
})

