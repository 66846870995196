const accordions = document.querySelectorAll('.text-accordion.foldable')
for (const accordion of accordions) {
  const foldButton = accordion.querySelector('.fold-button')
  foldButton.addEventListener('click', () => {
    accordion.classList.toggle('expanded')
    if (accordion.classList.contains('expanded')) {
      foldButton.innerHTML = 'zwiń'
    } else {
      foldButton.innerHTML = 'rozwiń'
    }
  })
}
