const contrastButtons = document.querySelectorAll(
  '.accesibility-list .page-contrast',
)
const pageBody = document.querySelector('body')
let darkMode = localStorage.getItem("darkMode");
if (contrastButtons) {
  contrastButtons.forEach(button => {
    button.addEventListener('click', () => {
      // pageBody.classList.toggle('contrast')
        darkMode = localStorage.getItem("darkMode");
        if (darkMode == "true") {
            removeDarkMode();
        } else {
            addDarkMode();
        }
    })
  })
}

if (darkMode === "true") {
    addDarkMode();
}

function addDarkMode() {
    darkMode = localStorage.setItem("darkMode", "true");
    pageBody.classList.toggle('contrast')
}

function removeDarkMode() {
    darkMode = localStorage.setItem("darkMode", "false");
    pageBody.classList.toggle('contrast')
}