$(document).ready(function() {
    // VARIABLES
    var defaultViewOptions;

    // SEARCH / AUTOCOMPLETE
    var searchInput = document.getElementById('search-input');
    var autocomplete = undefined;
    var autocompleteOffset = 3;
    var autocompleteOptions = {
      fields:  ["address_components", "formatted_address", "geometry", "icon", "name"],
      types: ["geocode"],
      componentRestrictions: { country: "pl" },
    };
    var defaultSelectZoom = 11;
    var routeSelectZoom = 13;
    var defaultBokViewZoom = 16;

    // LOCALIZATION
    var defaultLocalizationZoom = 13;
    var localizationCoords = null;
    var localizationMarker = null;

    // MAP
    // var mapDiv = $("#map")[0];
    // var bokMap;
    // var center = null;
    var selectedPoint = null;
    var bokRegPoints = [];

    // FLAGS
    var defaultView = false;
    var activeRegion = null;
    var draggableMap = true;
    var sidePanelActive = false;
    var activeFilters = {
        "customer_service": false,
        "extended_opening_hours": false,
        "payments": false,
        "prepaid_codes": false,
        "parking": false,
        "weekends": false,
        "kids_place": false
    };

    // MARKER IMAGES
    var markerImages = {
        'oddzial': {
            'default': '/static/ev2/images/bok/map-bok-marker.png',
            'hover': '/static/ev2/images/bok/map-bok-marker-hover.png',
        },
        'bok': {
            'default': '/static/ev2/images/bok/map-bok-marker.png',
            'hover': '/static/ev2/images/bok/map-bok-marker-hover.png',
        },
        'bokRegPoint': {
            'default': '/static/ev2/images/bok/map-bok-marker.png',
            'hover': '/static/ev2/images/bok/map-bok-marker-hover.png',
        },
        'mbok': {
            'default': '/static/ev2/images/bok/map-bok-marker-new.png',
            'hover': '/static/ev2/images/bok/map-bok-marker-new-hover.png',
        },
        'localization': {
            'default': '/static/ev2/images/bok/map-localisation-marker.png',
            'hover': '/static/ev2/images/bok/map-localisation-marker-hover.png',
        },
    };

    function returnBokRegPointsConfiguration() {
        // console.log(regionsJson);
        return bokRegPoints = {
            "poznan": {
                "active": false,
                "marker": null,
                "type": "bokRegPoint",
                "region": "poznan",
                "cityCoords": new google.maps.LatLng(52.4006548, 16.7612409),
                "regionCoords": new google.maps.LatLng(52.55, 17.00),
                "regionZoom": 8,
            },
            "bydgoszcz": {
                "active": false,
                "marker": null,
                "type": "bokRegPoint",
                "region": "bydgoszcz",
                "cityCoords": new google.maps.LatLng(53.1257695, 17.9679714),
                "regionCoords": new google.maps.LatLng(53.20, 18.10),
                "regionZoom": 8,
            },
            "gorzowWielkopolski": {
                "active": false,
                "marker": null,
                "type": "bokRegPoint",
                "region": "gorzowWielkopolski",
                "cityCoords": new google.maps.LatLng(52.7313747, 15.1831303),
                "regionCoords": new google.maps.LatLng(52.85, 15.30),
                "regionZoom": 9,
            },
            "szczecin": {
                "active": false,
                "marker": null,
                "type": "bokRegPoint",
                "region": "szczecin",
                "cityCoords": new google.maps.LatLng(53.4298185, 14.4841988),
                "regionCoords": new google.maps.LatLng(53.65, 14.70),
                "regionZoom": 9,
            },
            "zielonaGora": {
                "active": false,
                "marker": null,
                "type": "bokRegPoint",
                "region": "zielonaGora",
                "cityCoords": new google.maps.LatLng(51.9378334, 15.4415656),
                "regionCoords": new google.maps.LatLng(51.95, 15.60),
                "regionZoom": 9,
            },
        };
    }

    const localisationsTypes = [
        {"shortname":"bok","name":"Biuro Obsługi Klienta"},
        {"shortname":"mbok","name":"Mobilne Biuro Obsługi Klienta"},
        {"shortname":"oddzial","name":"Oddział"}
    ];

    var categoriesMap = {
        'customer_service': 'Obsługa klienta',
        'extended_opening_hours': 'Wydłużone godziny otwarcia',
        'payments': 'Płatności',
        'prepaid_codes': 'Kody przedpłatowe',
        'parking': 'Parking',
        'weekends': 'Czynne w weekendy',
        'kids_place': 'Kącik dla dzieci',
    };

    var facilitiesIconsMap = {
        'customer_service': '<i class="icon client-service-small align-middle"></i>',
        'extended_opening_hours': '<i class="icon clock-blue-small align-middle"></i>',
        'payments': '<i class="icon payments-small align-middle"></i>',
        'prepaid_codes': '<i class="icon stars-small align-middle"></i>',
        'parking': '<i class="icon parking-small align-middle"></i>',
        'weekends': '<i class="icon open-star-small align-middle"></i>',
        'kids_place': '<i class="icon teddy-small align-middle"></i>',
    };

    function setPointMarkers() {
        for (var location in bokPointsJson) {
          if (bokPointsJson[location].hasOwnProperty("region")) {
            if(regionsJson.find((r) => r.key == bokPointsJson[location].region) !== "undefined") {
                createPointMarker(bokPointsJson[location]);
            }
          }
        }
    }

    function createPointMarker(location) {
        if (
          !markerImages[location.type] ||
          !markerImages[location.type]["default"] ||
          !markerImages[location.type]["hover"]
        ) return;

        var markerIcon = markerImages[location.type]["default"];
        var markerIconHover = markerImages[location.type]['hover'];

        var marker = new google.maps.Marker({
          position: location.coords,
          map: map,
          icon: markerIcon,
        });

        google.maps.event.addListener(marker, 'mouseover', function() {
            marker.setIcon(markerIconHover);
        });

        google.maps.event.addListener(marker, 'mouseout', function() {
            marker.setIcon(markerIcon);
        });

        google.maps.event.addListener(marker, 'click', function () {
            selectPointOnClick(location);
            loadLocation(location);
            // printList();
        });

        location.marker = marker;
    }

    function loadLocation(location) {
        if(location === null && selectedPoint !== null) location = selectedPoint;
        if(location === null) {
            // console.log('Brak lokalizacji!');
            return;
        }

        var findRoute;
        var distance;
        if (localizationMarker !== null) {
            var findRouteHref = 'https://www.google.com/maps/dir/' + localizationCoords + '/' + location.address;
            findRoute = '<a href="' + findRouteHref + '" target="_blank">Wyznacz trasę</a>' + '<br>';
            distance = getDistanceFromLocations(
              location.marker.getPosition().lat(),
              location.marker.getPosition().lng(),
              localizationMarker.getPosition().lat(),
              localizationMarker.getPosition().lng()
            );
            distance = (Math.round(distance / 100) / 10).toString() + ' km';
            distance = '<p>' + '<i class="icon cursor align-middle"></i>' + distance.replace(".", ",") + '</p>';
        } else {
            findRoute = '';
            distance = '';
        }

        let street = location.hasOwnProperty("name") ? location.name : '';
        let typeFullName = localisationsTypes.find(l => l.shortname === location.type);

        var facilities = '';
        for (var cat in location.categories) {
            if (location.categories.hasOwnProperty(cat)
                && cat !== 'extended_opening_hours'
                && location.categories[cat] === true) {
                var fac = '<p>' + facilitiesIconsMap[cat] + categoriesMap[cat] + '</p>';
                facilities += fac;
            }
        }

        let extendedOpeningHours = location.categories.extended_opening_hours === true ? categoriesMap['extended_opening_hours'] : '';
        let mobilnym = location.type == "mbok" ? "mobilnym" : "";
        let appointment;

        if (location.appointment !== null) {
            appointment = '<a href="' + location.appointment + '" class="button" target="_blank">Umów wizytę w '+mobilnym+'BOK</a>';
            } else {
            appointment = '';
        }

        var mapDiv = '<div id="' + location.o_id + '" class="bok-location">'
            + '<div>'
            + '<p class="bok-name">'+ typeFullName.name +'</p>'
            + '<p class="bok-city">' + location.city + '</p>'
            + '<div class="bok-location-item">'
            + distance
            + street + '</br>'
            + location.codeAndCity + '</br>'
            + '<a href="#">' + findRoute  + '</a>' + '</div>'
            + '<div class="bok-location-item">'
            + extendedOpeningHours + ' '
            + location.hours + '</div>'
            + facilities
            + '</div>'
            + appointment
            + '<div class="bok-location-item">'
            + '<p><a href="https://www.mojeplatnosci.pl/" target="_blank">Doładuj energię kodem przedpłatowym</a></p><br>'
            + '<p><a href="https://www.pekao.com.pl/placowki_bankomaty" target="_blank">Sprawdź gdzie zapłacisz bez prowizji</a></p>'
            + '</div>'
            + '</div>';

        $("#"+window.activeParent + " .map-title_" + window.activeType).text(typeFullName.name + ' - ' + decodeURIComponent((location.address + '').replace(/\+/g, '%20')));

        $('#'+window.activeParent +' .panel-div').empty().html(mapDiv);
        $('#'+window.activeParent +' .panel-outer-div').addClass("visible").show();
    }

    function selectPointOnClick(marker) {
        unselectPoint();
        changeMarkerIcon(
          marker.marker,
          markerImages[marker.type]["hover"],
          markerImages[marker.type]["default"]
        );
        selectedPoint = marker;
    }

    function unselectPoint() {
        if (selectedPoint !== null) {
            // var prevPoint = bokPoints[selectedPoint['region']][selectedPoint['index']];
            var prevPoint = selectedPoint;
            changeMarkerIcon(prevPoint.marker, markerImages[prevPoint.type]['default'], markerImages[prevPoint.type]['hover']);
            selectedPoint = null;
        }
    }

    function changeMarkerIcon(marker, defaultImg, hoverImg) {
        marker.setIcon(defaultImg);

        google.maps.event.clearListeners(marker, 'mouseover');
        google.maps.event.clearListeners(marker, 'mouseout');

        google.maps.event.addListener(marker, 'mouseover', function() {
            marker.setIcon(hoverImg);
        });
        google.maps.event.addListener(marker, 'mouseout', function() {
            marker.setIcon(defaultImg);
        });
    }

    function setRegionPointMarkers(regionPoints, localisations) {
        for (var regionName in regionPoints) {
            if (regionPoints.hasOwnProperty(regionName)) {
                if(regionsJson.find(rj =>  rj.value == regionName))
                    createRegionPointMarker(regionPoints, localisations, regionPoints[regionName]);
            }
        }
    }

    function createRegionPointMarker(regionPoints, localisations, regionPnt) {
      var markerIcon = markerImages[regionPnt["type"]]["default"];
      var markerIconHover = markerImages[regionPnt["type"]]["hover"];

      var marker = new google.maps.Marker({
        position: regionPnt["cityCoords"],
        map: null,
        icon: markerIcon,
        label: {
          color: "white",
          fontWeight: "900",
          text: localisations.filter(l => l.region == regionPnt['region']).length.toString()
        },
      });

      google.maps.event.addListener(marker, "mouseover", function() {
        marker.setIcon(markerIconHover);
      });

      google.maps.event.addListener(marker, "mouseout", function() {
        marker.setIcon(markerIcon);
      });

      marker.addListener("click", function() {
        regionMarkerOnClick(regionPoints, bokPointsJson, regionPnt, markerIcon);
      });

      regionPoints[regionPnt["region"]]["marker"] = marker;
    }

    // vdev
    function regionMarkerOnClick(regionPoints, points, regionPnt, icon) {
        // hideSelectionContainer();
        // console.log('region clicked.... ');
        defaultView = false;
        activeRegion = regionPnt['region'];
        map.setCenter(regionPnt['regionCoords']);
        center = regionPnt['regionCoords'];
        map.setZoom(regionPnt["regionZoom"]);
        removeLocalizationMarker();
        filterVisiblePoints();
        // filterPoints(regionPoints, points);
        // switchSidePanel();
        // printList();
        regionPoints[regionPnt['region']]['marker'].setIcon(icon);
    }

    function removeLocalizationMarker () {
        if (localizationMarker !== null) {
            localizationCoords = null;
            if (localizationMarker.getMap() !== null) {
                localizationMarker.setMap(null);
            }
            localizationMarker = null;
        }
    }

    function enableAllPointsBelongingToRegion () {
        for(var location in bokPointsJson) {
            if(bokPointsJson[location].marker !== null && bokPointsJson[location].region === activeRegion) {
                enableVisiblePointMarker(bokPointsJson[location].marker);
            }
        }
    }

    function filterVisiblePoints() {
        if (defaultView === false) {
            disableAllRegionPoints(bokRegPoints);
            if (activeRegion !== null) {
                enableAllPointsBelongingToRegion();
                // for (var bokIndex in points[activeRegion]) {
                //     if (points[activeRegion].hasOwnProperty(bokIndex)) {
                //         filterCategories(points, points[activeRegion][bokIndex]);
                //         setPointPositionStatus(points[activeRegion][bokIndex]);
                //     }
                // }
            } else {
                // console.log('Brak aktywnego regionu...');
                enableAllVisiblePoints();
                // enableAllPoints(points, activeRegion);
                // for (var regionName in points) {
                //     if (points.hasOwnProperty(regionName)) {
                //         for (var bokIndex in points[regionName]) {
                //             if (points[regionName].hasOwnProperty(bokIndex)) {
                //                 filterCategories(points, points[regionName][bokIndex]);
                //                 setPointPositionStatus(points[regionName][bokIndex]);
                //             }
                //         }
                //     }
                // }
            }
        } else {
            enableAllRegionPoints(bokRegPoints);
            disableAllVisiblePoints();
        }
    }

    function disableAllRegionPoints(regionPoints) {
        for (var regionName in regionPoints) {
            if (regionPoints.hasOwnProperty(regionName)) {
                disableRegionPointMarker(regionPoints, regionPoints[regionName]);
            }
        }
    }

    function disableRegionPointMarker(regionPoints, regionPnt) {
        regionPoints[regionPnt['region']]['marker'].setMap(null);
        regionPoints[regionPnt['region']]['active'] = false;
    }

    function enableVisiblePointMarker(marker) {
        // console.log('Dodawanie markera dla: ');
        // console.log(marker);
        marker.setMap(map);
    }

    function disableVisiblePointMarker(marker) {
        // console.log("Usuwanie markera dla: ");
        // console.log(marker);
        marker.setMap(null);
        // points[pnt['region']][pnt['index']]['active'] = false;
    }

    function isPointOutsideMap(pnt) {
        // console.log('Point:');
        // console.log(pnt);
        if (map.getBounds() !== undefined && map.getBounds().hasOwnProperty('contains')) {
            return !map.getBounds().contains(pnt['marker'].getPosition());
        }

        return false;
    }

    function enableAllVisiblePoints() {
        for (var localisation in bokPointsJson) {
          if (bokPointsJson[localisation].marker !== null) {
            enableVisiblePointMarker(bokPointsJson[localisation].marker);
          }
        }
    }

    function disableAllVisiblePoints() {
        for (var localisation in bokPointsJson) {
            if (bokPointsJson[localisation].marker !== null) {
                // console.log('Wyłączanie markera z mapy: ' + bokPointsJson[localisation].o_id);
                disableVisiblePointMarker(bokPointsJson[localisation].marker);
            } else {
                // console.log('Brak markera dla: ' + bokPointsJson[localisation].o_id);
            }
        }
    }

    function getFlatPointsArray(points) {
        var bokPointsFlat = [];
        var cnt = 0;
        for (var regionName in points) {
            if (points.hasOwnProperty(regionName)) {
                for (var bokIndex in points[regionName]) {
                    if (points[regionName].hasOwnProperty(bokIndex)) {
                        bokPointsFlat[cnt] = points[regionName][bokIndex];
                        cnt++;
                    }
                }
            }
        }

        return bokPointsFlat;
    }

    function generateList(pointsFlat) {
        var bokList = '';
        for (var position in pointsFlat) {
            if (pointsFlat.hasOwnProperty(position)) {
                if ((selectedPoint === null
                        && pointsFlat[position]['active'])
                    || (selectedPoint !== null
                        && selectedPoint['region'] === pointsFlat[position]['region']
                        && selectedPoint['index'] === pointsFlat[position]['index'])) {
                    var extended_opening_hours = pointsFlat[position]['categories']['extended_opening_hours'] === 1 ? '<p>' + '<i class="icon clock-blue-small"></i>' + categoriesMap['extended_opening_hours'] + '</p>' : '';

                    var findRoute;
                    var distance;
                    if (localizationMarker !== null) {
                        var findRouteHref = 'https://www.google.com/maps/dir/' + localizationCoords + '/' + pointsFlat[position]['address'];
                        findRoute = '<a href="' + findRouteHref + '" target="_blank">Wyznacz trasę</a>' + '<br>';
                        distance = getDistanceFromLocations(
                            pointsFlat[position]['coords']['lat'],
                            pointsFlat[position]['coords']['lng'],
                            localizationCoords.lat(),
                            localizationCoords.lng()
                        );
                        distance = (Math.round(distance / 100) / 10).toString() + ' km';
                        distance = '<p>' + '<i class="icon cursor"></i>' + distance.replace(".", ",") + '</p>';
                    } else {
                        findRoute = '';
                        distance = '';
                    }
                    var mobilnym=''
                    var mbok=pointsFlat[position]['mbok'];
                    var newreservation=pointsFlat[position]['newreservation'];
                    if(mbok!==null){ mbok='Mobilne ';mobilnym='mobilnym ';}else{mbok='';mobilnym='';}

                    var appointment;

                    if (pointsFlat[position]['appointment'] !== null) {
                        appointment = '<a href="' + pointsFlat[position]['appointment'] + '" class="button" target="_blank">Umów wizytę w '+mobilnym+'BOK</a>';
                       } else {
                        appointment = '';
                    }

                    var facilities = '';
                    for (var cat in pointsFlat[position]['categories']) {
                        if (pointsFlat[position]['categories'].hasOwnProperty(cat)
                            && cat !== 'extended_opening_hours'
                            && pointsFlat[position]['categories'][cat] === 1) {
                            var fac = '<p>' + facilitiesIconsMap[cat] + categoriesMap[cat] + '</p>';
                            facilities += fac;
                        }
                    }

                    var bokDiv = '<div id="' + pointsFlat[position]['o_id'] + '" class="bok-location">'
                        + '<div>'
                        + '<p class="bok-name">'+mbok+'Biuro Obsługi Klienta</p>'
                        + '<p class="bok-city">' + pointsFlat[position]['city'] + '</p>'
                        + '<div class="bok-location-item">'
                        + distance
                        + pointsFlat[position]['place'] + '</br>'
                        + pointsFlat[position]['codeAndCity'] + '</br>'
                        + '<a href="#">' + findRoute  + '</a>' + '</div>'
                        + '<div class="bok-location-item">'
                        + extended_opening_hours
                        + pointsFlat[position]['hours'] + '</div>'
                        + facilities
                        + '</div>'
                        + appointment
                        + '<div class="bok-location-item">'
                        + '<p><a href="https://www.mojeplatnosci.pl/" target="_blank">Doładuj energię kodem przedpłatowym</a></p><br>'
                        + '<p><a href="https://www.pekao.com.pl/placowki_bankomaty" target="_blank">Sprawdź gdzie zapłacisz bez prowizji</a></p>'
                        + '</div>'
                        + '</div>';

                    bokList += bokDiv;
                }
            }
        }

        return bokList;
    }

    function getDistanceFromLocations(lat1,lon1,lat2,lon2) {
        var R = 6371009;
        var dLat = toRadians(lat2-lat1);
        var dLon = toRadians(lon2-lon1);
        var a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return R * c;
    }

    function toRadians (angle) {
        return angle * (Math.PI / 180);
    }

    function minimizeSidePanelOnClick() {
        var panelDiv = $('#panel-div');
        var bokLocationsListParent = panelDiv.parent();
        var closeBtn = $('.panel-div-close');
        closeBtn.off();

        closeBtn.on('click', function() {
            bokLocationsListParent.toggleClass('bok-list-hidden');
            var btnTextSpan = closeBtn.find('span').eq(1);
            if (btnTextSpan.text() === 'Zwiń') {
                btnTextSpan.text('Rozwiń');
            } else {
                btnTextSpan.text('Zwiń');
            }
            if (bokLocationsListParent.hasClass('bok-list-hidden')) {
                panelDiv.children().hide();
            } else {

                panelDiv.children().show();
            }
            removeOverlay();
        });
    }

    function isEmptyList() {
        var cnt = 0;
        pointsFlat = getFlatPointsArray(bokPoints);
        for (var position in pointsFlat) {
            if (pointsFlat.hasOwnProperty(position)) {
                if ((pointsFlat[position]['active'] && !pointsFlat[position]['outsideMap'])) {
                    cnt++;
                }
            }
        }
        if (cnt === 0) {
            return true;
        } else {
            return false;
        }
    }

    function hideSidePanelIfEmpty(target ) {
        var panelOuterDiv = $(target + ' panel-outer-div');
        var closeBtnText = $(target + ' .panel-div-close').find('span').eq(1);
        if (isEmptyList()) {
            hideSidePanel();
            removeOverlay();
        } else {
            showSidePanel();
        }
        if (panelOuterDiv.hasClass('bok-list-hidden')) {
            closeBtnText.text('Rozwiń');
        } else {
            closeBtnText.text('Zwiń');
        }
    }

    function enableAllRegionPoints(regionPoints) {
        for (var regionName in regionPoints) {
            if (regionPoints.hasOwnProperty(regionName)) {
                enableRegionPointMarker(regionPoints, regionPoints[regionName]);
            }
        }
    }

    //vdev
    function enableRegionPointMarker(regionPoints, regionPnt) {
        regionPoints[regionPnt['region']]['marker'].setMap(map);
        regionPoints[regionPnt['region']]['active'] = true;
    }

    function setUpEventListeners() {
        unselectPointOnClick();
        selectRegionOnClick();
        // changeFilterOnClick();
        switchAutocompleteOnKeyUp();
        localizeUserOnClick();
        showDefaultViewOnClick();
        lockMapOnClick();
        // updatePointsStatusOnBoundsChanged();
        // goToMapOnClick();
        // switchMapTableOnClick();
        // toggleMapFiltersOnClick();
    }

    // switchMapTableOnClick - function that switches map/table view.
    function switchMapTableOnClick() {
        var buttons = $('#switch-map-table').find('button');
        buttons.on('click', function () {
            buttons.toggleClass('active');
            switchMapTableCallback($(this).text());
        });
    }

    function switchMapTableCallback(text) {
        if (text === 'Mapa') {
            $('#map-tab-div').addClass('active');
            $('#list-tab-div').removeClass('active');
            $('#map-button').addClass('active');
            $('#bok-button').removeClass('active');
        } else if (text === 'Lista BOK i mobilnego BOK') {
            $('#list-tab-div').addClass('active');
            $('#map-tab-div').removeClass('active');
            $('#bok-button').addClass('active');
            $('#map-button').removeClass('active');
        }
    }

    // switchAutocompleteOnKeyUp - listener that observe search input and switch on/off autocomplete feature.
    function switchAutocompleteOnKeyUp() {
        var searchInputJQuery = $('#search-input');
        searchInputJQuery.on('keyup', function () {
            var length = this.value.length;
            if (length > autocompleteOffset - 1) {
                if (autocomplete === undefined) {
                    enableAutocomplete();
                }
            } else if (length === autocompleteOffset - 1) {
                disableAutocomplete();
                enableAutocomplete();
            } else if (length < autocompleteOffset - 1) {
                if (autocomplete !== undefined) {
                    disableAutocomplete();
                }
            }
        });
    }

    function enableAutocomplete() {
        autocomplete = new google.maps.places.Autocomplete(
              searchInput,
              autocompleteOptions
            );

        // google.maps.event.addListener(autocomplete, 'place_changed', function () {
        //     autocompleteSelectCallback(autocomplete.getPlace());
        // });

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert("Brak danych dla: '" + place.name + "'");
            return;
            }

            //   const marker = new google.maps.Marker({
            //     map,
            //     anchorPoint: new google.maps.Point(0, -29),
            //   });

            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
            } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
            }

            // hideSelectionContainer();

            var lat = place.geometry.location.lat();
            var lng = place.geometry.location.lng();
            var coords = new google.maps.LatLng(lat, lng);
            // var autocompleteZoom = place.types.indexOf('route') === -1 ? defaultSelectZoom : routeSelectZoom;
            var autocompleteZoom = place.types.indexOf('route') === -1 ? defaultSelectZoom : routeSelectZoom;

            unselectPoint();
            defaultView = false;
            activeRegion = null;
            map.setCenter(coords);
            center = coords;
            map.setZoom(autocompleteZoom);
            removeLocalizationMarker();
            // filterPoints(bokRegPoints, bokPoints);
            // switchSidePanel();
            // printList();

            // marker.setPosition(place.geometry.location);
            // marker.setVisible(true);
        });

        // autocomplete.bindTo("bounds", map);
    }

    function autocompleteSelectCallback(place) {
        // hideSelectionContainer();

        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
        var coords = new google.maps.LatLng(lat, lng);
        // var autocompleteZoom = place.types.indexOf('route') === -1 ? defaultSelectZoom : routeSelectZoom;
        var autocompleteZoom = place.types.indexOf('route') === -1 ? defaultSelectZoom : routeSelectZoom;

        unselectPoint();
        defaultView = false;
        activeRegion = null;
        map.setCenter(coords);
        center = coords;
        map.setZoom(autocompleteZoom);
        removeLocalizationMarker();
        filterPoints(bokRegPoints, bokPoints);
        // switchSidePanel();
        // printList();
    }

    function disableAutocomplete() {
        $(".pac-container").remove();
        google.maps.event.clearListeners(searchInput);
        autocomplete = undefined;
    }

    // localizeUserOnClick - function that localize user on the map, set localization marker and show/refresh side panel if required
    function localizeUserOnClick() {
        var button = $('.localization-button');
        button.on('click', function () {
            if (navigator && navigator.geolocation && navigator.permissions) {
                // alert('Użyto W3C Geolocation API.');
                w3cGeolocation();
            } else {
                // alert('Twoja przeglądarka nie wspiera W3C Geolocation API. Użyto Google Geolocation API.');
                googleGeolocation();
            }
        });
    }

    function isMobileDevice() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    }

    function w3cGeolocation() {
        navigator.permissions.query({name:'geolocation'})
            .then(function(permissionStatus) {
                if (permissionStatus.state === 'denied') {
                    alert('Aby kontynuować, zezwól witrynie '+window.location.host+' na korzystanie z usługi lokalizacji.');
                } else {
                    if (window.location.href.indexOf('https://') !== -1) {
                        navigator.geolocation.getCurrentPosition(navSuccessCallback, navErrorCallback);
                    } else {
                        // alert('W3C Geolocation API wymaga szyfrowanego połączenia. Użyto Google Geolocation API.');
                        googleGeolocation();
                    }
                }
            });
    }

    function navSuccessCallback(position) {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        localizeUserCallback(lat, lng);
    }

    function localizeUserCallback(lat, lng) {
        // hideSelectionContainer();
        var coords = new google.maps.LatLng(lat, lng);
        unselectPoint();
        defaultView = false;
        activeRegion = null;
        map.setCenter(coords);
        center = coords;
        map.setZoom(defaultLocalizationZoom);
        setLocalizationMarker(coords);
        // filterPoints(bokRegPoints, bokPoints);
        // if (!defaultView && window.innerWidth > 800) {
        //     // Don't show popup on mobile after geolocation
        //     // switchSidePanel();
        //     // printList();
        // }
    }

    function setLocalizationMarker (coords) {
        if (localizationMarker === null) {
            localizationCoords = coords;

            var markerIcon = markerImages['localization']['default'];
            var markerIconHover = markerImages['localization']['hover'];

            var marker = new google.maps.Marker({
                position: coords,
                map: map,
                icon: markerIcon,
            });

            google.maps.event.addListener(marker, 'mouseover', function() {
                marker.setIcon(markerIconHover);
            });

            google.maps.event.addListener(marker, 'mouseout', function() {
                marker.setIcon(markerIcon);
            });

            localizationMarker = marker;
        } else {
            localizationMarker.setPosition(coords);
        }
    }

    function navErrorCallback(error) {
        if (error.message === 'User denied Geolocation') {
            if (isMobileDevice()) {
                alert('Aby kontynuować, włącz lokalizację urządzenia korzystającą z usługi lokalizacyjnej Google oraz zezwól witrynie https://www.operator.enea.pl na korzystanie z niej.');
            } else {
                alert('Aby kontynuować, zezwól witrynie https://www.operator.enea.pl na korzystanie z usługi lokalizacji.');
            }
        }
    }

    function googleGeolocation() {
        $.ajax({
            url: 'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCAYEjli0glO_PcHjXTDwIiTOPnQLCwYm4',
            type: 'POST',
            headers: {
                "Content-Type":"application/json"
            },
            data: {},
            success: function (data) {
                if (data['location']['lat'] !== undefined && data['location']['lng'] !== undefined) {
                    var lat = data['location']['lat'];
                    var lng = data['location']['lng'];
                    localizeUserCallback(lat, lng);
                }
            },
            error: function (e) {
                alert('Nie udało się zlokalizować urządzenia.');
                // console.log(e.responseJSON);
            }
        });
    }

    function unselectPointOnClick() {
        map.addListener('click', function () {
            if (!defaultView) {
                unselectPoint();
                // printList();
            }
        });
    }

    // showDefaultViewOnClick - function that shows default view after clicking 'Regiony' button.
    function showDefaultViewOnClick() {
        var button = $('#'+window.activeParent+' .show-default-view');
        // console.log(button);
        button.on('click', function () {
            // hideSelectionContainer();
            // console.log('clikced regiony [daefaultView]: ' + defaultView);
            // defaultView = !defaultView; // toggle
            // hideAllNoneRegionMarkers();
            defaultViewOptions = getDefaultViewOptions(); // resseting default view
            if (!defaultView) {
                defaultView = true;
                activeRegion = null;
                unselectPoint();
                map.setCenter(defaultViewOptions['center']);
                // center = defaultViewOptions['center'];
                map.setZoom(defaultViewOptions['zoom']);
                removeLocalizationMarker();
                filterVisiblePoints(bokRegPoints);
                // switchSidePanel();
                // $('#'+window.activeParent+' .panel-div').parent().removeClass('bok-list-hidden');
            }
        });
    }

    // changeFilterOnClick - function that set filter when checkbox was clicked and refresh markers and bok list in side panel if required.
    function changeFilterOnClick() {
        var checkbox = $('#'+window.activeParent+' .bok__filters').find('input[type=checkbox]');
        checkbox.on('click', function () {
            setFilter(this.id, this.checked);
            if (defaultView === false) {
                // filterPoints(bokRegPoints, bokPoints);
                if (sidePanelActive) {
                    // printList();
                }
            }
        });
    }

    function setFilter(category, checked) {
        if (checked === true) {
            activeFilters[category] = true;
        } else {
            activeFilters[category] = false;
        }
    }

    // toggleMapFiltersOnClick - function that shows/hides filters section on mobile.
    function toggleMapFiltersOnClick() {
        var toggleButton = $('#'+window.activeParent+' .bok__filters').find('> a');

        toggleButton.on('click', function (e) {
            e.preventDefault();
            $('#'+window.activeParent+' .bok__filters').find('.bok__filter-item').toggleClass('active');
        })
    }

    // lockMapOnClick - function that enables/disables possibility of dragging map.
    function lockMapOnClick() {
        var button = $('#'+window.activeParent+' .lock-map');
        button.on('click', function () {
            if (draggableMap === true) {
                map.setOptions({draggable: false});
                draggableMap = false;
                $(this).find('.icon.lock').removeClass('off');
            } else {
                map.setOptions({draggable: true});
                draggableMap = true;
                $(this).find('.icon.lock').addClass('off');
            }
        });
    }

    // selectRegionOnClick - function that changes default view to specific region view.
    function selectRegionOnClick() {
        let regionFilter = $('#'+window.activeParent+' .map-container-overlay').find('#'+window.activeParent+' .bok__cities .link');
        regionFilter.on('click', function () {
            let region = $(this).data('region');
            if (Object.keys(bokRegPoints).indexOf(region) !== -1) {
                // regionMarkerOnClick(bokRegPoints, bokPoints, bokRegPoints[region], markerImages['bokRegPoint']['default']);
                regionMarkerOnClick(bokRegPoints, bokPointsJson, bokRegPoints[region], markerImages['bokRegPoint']['default']);
            }
        });
    }

    // updatePointsStatusOnBoundsChanged - function that set marker object 'outsideMap' property according to current markers positions.
    function updatePointsStatusOnBoundsChanged() {
        google.maps.event.addListener(map, 'bounds_changed', function() {
            updatePointsStatus(bokPoints);
            if (!defaultView && window.innerWidth > 800) {
                printList();
            }
        });
    }

    function updatePointsStatus(points) {
        // console.log('updating point status');
        for (var regionName in points) {
            if (points.hasOwnProperty(regionName)) {
                for (var bokIndex in points[regionName]) {
                    if (points[regionName].hasOwnProperty(bokIndex)) {
                        filterCategories(points, points[regionName][bokIndex]);
                        setPointPositionStatus(points[regionName][bokIndex]);
                    }
                }
            }
        }
    }

    // goToMapOnClick - function that shows map view and set map center to selected bok point.
    function goToMapOnClick() {
        var goToMapLink = $('.contact-section__tab-content-column').find('a:not(.contact-schedule-link)');
        goToMapLink.on('click', function (e) {
            e.preventDefault();
            var o_id = $(this).data('o_id');
            goToMap(o_id);
            $('#map-button').addClass('active');
            $('#bok-button').removeClass('active');
        });
    }

    function goToMap(o_id) {
        var point = findPoint(bokPoints, o_id);
        if (point !== null) {
            // switchMapTableCallback('Mapa');
            // hideSelectionContainer();
            goToMapPoint(point);
        }
    }

    function findPoint(points, o_id) {
        for (var regionName in points) {
            if (points.hasOwnProperty(regionName)) {
                for (var bokIndex in points[regionName]) {
                    if (points[regionName].hasOwnProperty(bokIndex)) {
                        if (points[regionName][bokIndex]['o_id'] === o_id) {
                            return points[regionName][bokIndex];
                        }
                    }
                }
            }
        }

        return null;
    }

    function goToMapPoint(pnt) {
        // console.log("goToMapPoint:");
        // console.log(pnt);
        defaultView = false;
        activeRegion = null;
        map.setCenter(pnt['coords']);
        center = pnt['coords'];
        map.setZoom(13);
        selectPointOnClick(pnt);
        // filterPoints(bokRegPoints, bokPoints);
        // switchSidePanel();
        // printList();
        $('#'+window.activeParent+ ' .panel-div').parent().removeClass('bok-list-hidden');
    }

    function bokCarouselMobile() {
        var panelDivLen = $('#panel-div').children().length;
        if (panelDivLen > 1) {
            var slider = $('[data-js="mediaCarousel"]');
            slider.slick({
                responsive: [
                    {
                        breakpoint: 9999,
                        settings: 'unslick'
                    },
                    {
                        breakpoint: 801,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            dots: false,
                            centerPadding: "40px",
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            centerPadding: "50px"
                        }
                    }
                ]
            });
        }
        addOverlay();
    }

    // function addOverlay() {
    //     var panelOuterDiv = $('#panel-outer-div');
    //     if (panelOuterDiv.hasClass('visible')) {
    //         $('body').addClass('with-overlay');
    //     }
    // }

    // function removeOverlay() {
    //     $('body').removeClass('with-overlay');
    // }

    function getDefaultViewOptions() {
        return (defaultViewOptions = {
          mapTypeId: "roadmap",
          center: new google.maps.LatLng(52.75, 16.15),
          zoom: defaultBokViewZoom,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_LEFT,
          },
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
          streetViewControl: true,
          streetViewControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_LEFT,
          },
          fullscreenControl: false,
        });
    }

    function getMapsReady() {
        var openMapButtons = document.querySelectorAll(".mapShowButton").length;

        for (var i = 0; i < openMapButtons ; i++) {
            document.querySelectorAll(".mapShowButton")[i].addEventListener("click", function(e) {

                window.activeParent =  $(this).closest("div[id^='divisions-static-navy_']").attr("id");
                window.activeType = $("#" + window.activeParent+"_config").attr("data-layout-type");

                let lat = parseFloat(
                  $("#" + window.activeParent + "_map-modal_" + window.activeType + "_info").attr("data-bs-lat")
                );
                let lng = parseFloat(
                  $("#" + window.activeParent + "_map-modal_" + window.activeType + "_info").attr("data-bs-lng")
                );
                // let id = parseInt(e.path[0].getAttribute('data-bs-id'));
                let id = parseInt(
                  $("#" + window.activeParent + "_map-modal_"+window.activeType + "_info").attr("data-bs-id")
                );

                let facility = bokPointsJson.find(f => f.o_id === id);
                if(typeof facility !== "undefined")
                    loadLocation(facility);

                // DEFAULT VIEW OPTIONS
                defaultViewOptions = {
                  ...getDefaultViewOptions(),
                  ...{ center: { lat, lng }, zoom: defaultBokViewZoom },
                };
                map = new google.maps.Map($("#"+ window.activeParent + "_map_" + window.activeType)[0], defaultViewOptions);

                setPointMarkers();
                selectPointOnClick(facility);
                bokRegPoints = returnBokRegPointsConfiguration();
                setRegionPointMarkers(bokRegPoints, bokPointsJson);
                enableAllRegionPoints(bokRegPoints);

                enableAutocomplete();

                // showSidePanel();
                setUpEventListeners();
            });
        }
    }

    getMapsReady();
});

