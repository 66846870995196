import "jquery-pimcore-formbuilder/dist/dynamic-multi-file/jquery.fb.dmf.drop-zone";
import "jquery-pimcore-formbuilder/dist/jquery.fb.core";
import "jquery-pimcore-formbuilder/dist/jquery.fb.ext.recaptcha-v3";

import { DropzoneHandler } from "js-pimcore-formbuilder";

document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll("form.formbuilder.ajax-form").forEach((form) => {
        new DropzoneHandler(form);
    });
    $("form.formbuilder").formBuilderReCaptchaV3();

    const dzContainer = document.querySelector(".dropzone-container");

    if (dzContainer) {
        dzContainer.addEventListener("DOMNodeInserted", () => {
            const dzRemoveBtn = document.querySelectorAll(".dz-remove");

            dzRemoveBtn.forEach((btn) => {
                if (btn.innerText.trim() === "Remove file") {
                    btn.innerText = "Usuń plik";
                }
            });
        });
    }
});

$('form.ajax-form').on('formbuilder.success',
    function (ev, message, redirect, $form) {
        if (redirect) {
            document.location.href = redirect;
        } else {
            alert(message[0]['message']);
        }
    }
);


