const sideMenuItems = document.querySelectorAll(
    '.side-menu-item',
)

const sideMenuItemsHidden = document.querySelectorAll(
    '.side-menu-item.hide',
)

const sideMenuShow = document.querySelector(
    '.side-menu-show',
)

if (sideMenuItems && sideMenuItems.length > 4) {
    sideMenuShow.classList.add('active');

    sideMenuShow.addEventListener("click", (e) => {
        if (sideMenuShow.classList.contains('opened')) {
            sideMenuShow.classList.remove('opened')
            sideMenuItemsHidden.forEach( (el) => el.classList.add('hide'))
        } else {
            sideMenuShow.classList.add('opened')
            sideMenuItemsHidden.forEach( (el) => el.classList.remove('hide'))
        }
    })
}