import Splide from '@splidejs/splide'

const elementName = '#widget-7 .splide'
const elementSelector = document.querySelector(elementName)
if (elementSelector) {
 new Splide(elementName, {
   perPage: 1,
   type: 'loop',
   cover: true,
   heightRatio: 0.5,
 }).mount()
}