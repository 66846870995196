(function () {
  const popup = document.querySelector('#popupInfo');
  const closeModal = (element) => {
    element.classList.remove('show');
  };

  if (popup) {
    const wasClicked = sessionStorage.getItem('popupWasClicked');
    if (wasClicked) {
      closeModal(popup);
    } else {
      document.querySelector('#btn-close-modal-popupInfo').addEventListener('click', () => {
        sessionStorage.setItem('popupWasClicked', '1');
        closeModal(popup);
      });
    }
  }
}(document));
