if(document.querySelector('form') && document.querySelector('form').closest('.accordion-item')){
    let form = document.querySelector('form');
    let accordion = form.closest('.accordion-item');
    let accordionBody = form.closest('.accordion-body').parentElement.closest('.accordion-body');
    let accordionButton = accordion.querySelector('.accordion-button');

    if(form.querySelector('.div-alert .col-12 .alert-success') ||
    form.querySelector('.div-alert .col-12 .alert-danger') ||
    form.querySelector('.form-error-message') ){

        accordionButton.classList.remove('collapsed');
        accordionButton.setAttribute('aria-expanded', 'true');
        accordion.querySelector('.accordion-collapse').classList.add('show');

        if(accordionBody){
            let tabOpenName = accordionBody.closest('[aria-labelledby]').getAttribute('aria-labelledby')
            let tabOpenBody = document.querySelector(`[aria-labelledby="${tabOpenName}"]`);
            let tabOpen = document.querySelector(`[aria-controls="${tabOpenBody.id}"]`);

            let tabCloseName = tabOpenBody.parentElement.querySelector('[aria-labelledby]').getAttribute('aria-labelledby')
            let tabCloseBody = document.querySelector(`[aria-labelledby="${tabCloseName}"]`);
            let tabClose = document.querySelector(`[aria-controls="${tabCloseBody.id}"]`);

            tabOpen.classList.remove('collapsed');
            tabOpen.setAttribute('aria-expanded', 'true');
            tabOpenBody.classList.add('show');

            tabClose.classList.add('collapsed');
            tabClose.setAttribute('aria-expanded', 'false');
            tabCloseBody.classList.remove('show');
        }

        let yOffset = accordion.getBoundingClientRect().top + window.scrollY;
        if(form.querySelector('.form-error-message')){
            let divError = form.querySelector('.invalid-feedback').parentElement;
            yOffset = divError.getBoundingClientRect().top + window.scrollY;
            if(yOffset === 0){
                yOffset = accordion.getBoundingClientRect().top + window.scrollY+50;
            }
        }
        window.scrollTo({
            top: yOffset,
            behavior: 'smooth'
        });
    }
}