const cookiesWraper = document.getElementById('cookies')
const cookiesAction = document.getElementById('cookiesAction')

if (cookiesAction) {
  cookiesAction.addEventListener('click', e => {
    e.preventDefault()
    cookiesWraper.classList.remove('cookies-noconfirmed')
    localStorage.setItem('cookiesAccepted', true)
  })
}

if (cookiesWraper) {
  JSON.parse(localStorage.getItem('cookiesAccepted')) === true
    ? cookiesWraper.classList.remove('cookies-noconfirmed')
    : cookiesWraper.classList.add('cookies-noconfirmed')
}
