import Form from './form/Form'

const companyFormId = document.getElementById('company_oc_form')
const personFormId = document.getElementById('person_oc_form')

if (companyFormId) {
  let companyForm = new Form(companyFormId, true)
  companyForm.initialize()
}

if (personFormId) {
  let personForm = new Form(personFormId, true)
  personForm.initialize()
}

$('.buttons-wrapper .button').on('click', function() {
  if (!$('.active-form .form-page.show[data-page="1"]').length) {
    $('.choose-form-path > .trigger').hide()
  } else {
    $('.choose-form-path > .trigger').show()
  }
})

$('.choose-form-path input[type=radio][name=chooseForm]').change(function() {
  if (this.value == 'person') {
    $('.form-path.first').addClass('active-form')
    $('.form-path.second').removeClass('active-form')
  } else if (this.value == 'company') {
    $('.form-path.second').addClass('active-form')
    $('.form-path.first').removeClass('active-form')
  }
})
