const hash = 'db6bc4f9f020343d28133dbffe846c8f';
const container = '3b6fe46ed018437024c8b639a367feab';

$(`#${hash}`).click(function () {
    localStorage.setItem(hash, '1');
    $(`#${container}`).fadeOut();
});

if (localStorage.getItem(hash) === '1') {
    $(`#${container}`).addClass('d-none');
}

