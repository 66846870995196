const sizeButtons = document.querySelectorAll('.accesibility-list .page-size')
const htmlElement = document.querySelector('html')

if (sizeButtons) {
  sizeButtons.forEach(button => {
    button.addEventListener('click', () => {
      fontSize()
    })
  })
}

const fontSize = () => {
  const currentFontSize = document.querySelector('html').style.fontSize

  if (currentFontSize === '100%') {
    htmlElement.style.fontSize = '120%'
  } else if (currentFontSize === '120%') {
    htmlElement.style.fontSize = '140%'
  } else {
    htmlElement.style.fontSize = '100%'
  }
}
