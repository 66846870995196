import axios from 'axios'
import IMask from 'imask'
import loader from '../../components/loader'
import messagesPL from '../../../data/form/formData.json'
import messagesEN from '../../../data/form/formDataEn.json'
import validator from './validators'
import qq from 'fine-uploader'

const fields = {
  formPageSelector: '.form-page',
  errorShowClass: 'show',
  formComponent:
    '.input-wrapper, .textarea-wrapper, .checkbox-wrapper, .fine-uploader',
  errorMessageSelector: '.error-message',
  inputWithErrorClass: 'error',
  buttonNextPageSelector: '.next',
  buttonBackPageSelector: '.back',
  buttonSend: '.send',
}

export default class Form {
  constructor(form, hasPages = false, summaryHomeURL = '/') {
    this.form = form
    this.formName = form.dataset.name || 'form'
    this.formLanguage = this.form.dataset.lang || 'pl'
    this.hasPages = hasPages
    this.summaryHomeURL = summaryHomeURL
    this.isRecaptchaValid = false
    this.page = 1
    this.attachments = []
    this.erroTextJson = this.formLanguage === 'pl' ? messagesPL : messagesEN
  }

  initialize() {
    this.form.setAttribute('novalidate', 'novalidate')
    this.initFileUploader()
    this.initDatepickers()
    this.initInputCollections()
    if (this.hasPages) {
      this.initNaviEventListeners()
    }
    this.initFieldsetValidation()
    this.initSubmitHandler()
    this.hideFormStatus()
    this.initSummaryButtons()
    this.initDirtyMarker()
  }

  initDirtyMarker() {
    const allFormElements = [...this.form.elements]
    allFormElements.forEach(formElement => {
      formElement.addEventListener(
        'change',
        event => {
          event.target.setAttribute('dirty', '')
        },
        false,
      )
    })
  }

  initFileUploader() {
    const uploadFiles = this.form.querySelectorAll('.fine-uploader')

    if (uploadFiles) {
      uploadFiles.forEach(uploadFile => {
        const fieldName = uploadFile.dataset.name
        const uploaderID = uploadFile.id
        const waitingImage =
          '/static/ev2/images/fineuploader/waiting-generic.png'
        const notAvailableImage =
          '/static/ev2/images/fineuploader/not_available-generic.png'

        const fileEndpoint = (function() {
          if (uploadFile.getAttribute('data-endpoint') != null) {
            return uploadFile.getAttribute('data-endpoint')
          } else {
            return '/api/files/temporary'
          }
        })()

        const filesLimit = (function() {
          if (uploadFile.getAttribute('data-fileslimit') != null) {
            return uploadFile.getAttribute('data-fileslimit')
          } else {
            return 5
          }
        })()

        const fileSizeLimit = (function() {
          if (uploadFile.getAttribute('data-sizelimitMB') != null) {
            return (
              parseInt(uploadFile.getAttribute('data-sizelimitMB')) *
              1024 *
              1024
            )
          } else {
            return 5 * 1024 * 1024
          }
        })()

        const filesExtensions = (function() {
          if (uploadFile.getAttribute('data-filesextensions') != null) {
            let value = uploadFile.getAttribute('data-filesextensions')
            let newValue = value.split(',')
            return Array.from(newValue)
          } else {
            return ['doc', 'txt', 'odt', 'pdf', 'bmp', 'jpg', 'png']
          }
        })()

        new qq.FineUploader({
          element: uploadFile,
          template: 'qq-template-validation',
          request: {
            endpoint: fileEndpoint,
          },
          deleteFile: {
            enabled: true,
            endpoint: fileEndpoint,
          },
          thumbnails: {
            placeholders: {
              waitingPath: waitingImage,
              notAvailablePath: notAvailableImage,
            },
          },
          validation: {
            allowedExtensions: filesExtensions,
            itemLimit: filesLimit,
            sizeLimit: fileSizeLimit,
          },
          callbacks: {
            onDeleteComplete: (id, xhr, isError) => {
              if (!isError) {
                this.deleteAttachment(id, uploaderID)
              }
            },
            onComplete: (id, name, response) => {
              if (response.success) {
                this.setAttachment(response.filename, id, fieldName, uploaderID)
              }
            },
          },
          messages: {
            emptyError: this.getTranslation('validation.upload.emptyError'),
            sizeError: this.getTranslation('validation.upload.sizeError'),
            typeError: this.getTranslation('validation.upload.typeError'),
            minSizeError: this.getTranslation('validation.upload.typeError'),
            noFilesError: this.getTranslation('validation.upload.noFilesError'),
            onLeave: this.getTranslation('validation.upload.onLeave'),
            retryFailTooManyItemsError: this.getTranslation(
              'validation.upload.retryFailTooManyItemsError',
            ),
            tooManyItemsError: this.getTranslation(
              'validation.upload.tooManyItemsError',
            ),
          },
        })
      })
    }
  }

  setAttachment(responseName, fileID, fieldName, uploaderID) {
    let fileData = {
      id: `${uploaderID}_${fileID}`,
      fieldName: fieldName,
      responseFileName: responseName,
    }
    this.attachments.push(fileData)
  }

  deleteAttachment(fileID, uploaderID) {
    this.attachments = this.attachments.filter(function(obj) {
      return obj.id !== `${uploaderID}_${fileID}`
    })
  }

  initInputCollections() {
    let allFormInputGroups = this.form.querySelectorAll('.input-group')
    allFormInputGroups.forEach(inputGroup => {
      let counter = 1
      let cloneLimit = inputGroup.dataset.limit || 10
      let nextFlexRow = inputGroup.nextElementSibling
      if (nextFlexRow && nextFlexRow.classList.contains('input-group-button')) {
        let addButton = nextFlexRow.querySelector('.add-new-group')
        addButton.addEventListener('click', () => {
          let clone = inputGroup.cloneNode(true)
          clone.id = `group_${counter}`
          clone = clone.innerHTML.replace(/0/g, counter)

          let cloneWrapper = document.createElement('div')
          cloneWrapper.classList.add('row', 'input-group')
          let buttonContainer = document.createElement('div')
          buttonContainer.classList.add('remove-button-container')
          let removeButton = document.createElement('button')
          removeButton.classList = 'button decline remove-group'
          removeButton.textContent = 'Usuń'
          removeButton.addEventListener('click', () => {
            cloneWrapper.remove()
            addButton.style.display = 'block'
            counter--
          })
          cloneWrapper.innerHTML = clone
          buttonContainer.appendChild(removeButton)
          cloneWrapper.appendChild(buttonContainer)
          inputGroup.parentNode.insertBefore(cloneWrapper, nextFlexRow)
          const onlyDatapickers = cloneWrapper.querySelectorAll(
            `[data-validator="datepicker"]`,
          )
          const onlyInputs = cloneWrapper.querySelectorAll(`input`)
          this.initFieldsetValidation([...onlyInputs])
          this.initDatepickers(onlyDatapickers)
          counter++
          if (counter == cloneLimit) {
            addButton.style.display = 'none'
          } else {
            addButton.style.display = 'block'
          }
        })
      }
    })
  }

  initDatepickers(newDatapickers = []) {
    const allDatepickers =
      newDatapickers.length > 0
        ? newDatapickers
        : this.form.querySelectorAll('[data-validator="datepicker"]')

    const self = this
    allDatepickers.forEach(element => {
      let options = {
        language: this.formLanguage,
        dateFormat: 'dd-mm-yyyy',
        view: 'years',
        autoClose: true,
        maxDate: '',
        onSelect: function(dp) {
          self.testText(element)
          if (element.dataset.requiredif) {
            self.toggRequiredInput(element)
          }
        },
      }
      let maxAttr = element.dataset.max
      let viewAttr = element.dataset.view
      let today = new Date()
      if (maxAttr === 'today') {
        options.maxDate = today
      } else if (maxAttr === 'adult') {
        let year = today.getFullYear() - 18
        let newDate = today.setFullYear(year)
        options.maxDate = new Date(newDate)
      }
      if (viewAttr === 'years') {
        options.minView = 'years'
        options.dateFormat = 'yyyy'
      }
      $(function() {
        $(element).datepicker(options)
      })
    })
  }

  initSubmitHandler() {
    this.form.addEventListener('submit', e => {
      e.preventDefault()

      let isFormValid = true

      const allFormElements = [...this.form.elements]

      allFormElements.forEach(element => {
        if (
          element.nodeName.toUpperCase() === 'INPUT' &&
          !element.classList.contains('do-not-validate')
        ) {
          let elementType = element.type.toUpperCase()

          if (elementType === 'TEXT') {
            if (!this.testText(element)) isFormValid = false
          }

          if (elementType === 'NUMBER') {
            if (!this.testNumber(element)) isFormValid = false
          }

          if (elementType === 'TEL') {
            if (!this.testTel(element)) isFormValid = false
          }

          if (elementType === 'EMAIL') {
            if (!this.testText(element)) isFormValid = false
          }

          if (elementType === 'CHECKBOX') {
            if (!this.testCheckbox(element)) isFormValid = false
          }

          if (elementType === 'RADIO') {
            if (!this.testRadioElement(element)) isFormValid = false
          }
        }

        if (
          element.nodeName.toUpperCase() === 'TEXTAREA' &&
          !element.classList.contains('do-not-validate')
        ) {
          if (!this.testText(element)) isFormValid = false
        }

        if (
          element.nodeName.toUpperCase() === 'SELECT' &&
          !element.classList.contains('do-not-validate')
        ) {
          if (!this.testSelect(element)) isFormValid = false
        }
      })

      if (isFormValid) {
        this.submitForm()
      } else {
        return false
      }
    })
  }

  submitForm() {
    this.showLoading()
    const self = this.form
    const grecaptchaPublicKey = document.body.dataset.recaptchaPublicKey

    if (grecaptchaPublicKey) {
      let promise = new Promise((resolve, reject) => {
        grecaptcha.ready(function() {
          grecaptcha
            .execute(grecaptchaPublicKey, { action: 'form' })
            .then(token => {
              if (token) {
                let recaptchaInputId = `#${
                  self.querySelector('.enea-recaptcha').id
                }`
                self.querySelector(recaptchaInputId).value = token

                resolve(true)
              } else {
                reject(false)
              }
            })
        })
      })

      promise
        .then(() => {
          this.isRecaptchaValid = true
        })
        .catch(() => {
          this.isRecaptchaValid = false
        })
        .finally(() => {
          sendForm()
        })
    } else {
      sendForm()
    }

    const sendForm = async () => {
      if (!this.isRecaptchaValid) {
        let resp = {
          success: false,
          data: {
            header: 'Wystąpił błąd ReCaptcha',
            message: '',
          },
        }
        this.stopLoading()
        this.showSummary(resp)
      }

      try {
        const formMethod = this.form.getAttribute('method').toUpperCase()
        const formURL = this.form.getAttribute('action')
        let formData = new FormData(this.form)

        if (this.attachments.length > 0) {
          for (const [index, attachment] of this.attachments.entries()) {
            formData.set(
              `${this.formName}[${attachment.fieldName}][${index}]`,
              attachment.responseFileName,
            )
          }
        }

        if (formData.has('qqfile')) formData.delete('qqfile')

        await axios({
          method: formMethod,
          url: formURL,
          data: formData,
        }).then(result => {
          this.stopLoading()
          this.showSummary(result.data)
        })
      } catch (erro) {
        const resp = {
          success: false,
          data: {
            header: 'Wystąpił problem z wysłaniem formularza.',
            message:
              'Aby spróbować ponownie wysłać formularz, kliknij poniższy przycisk',
          },
        }
        this.stopLoading()
        this.showSummary(resp)
      }
    }
  }

  initNaviEventListeners() {
    let nextButton = this.form.querySelector(fields.buttonNextPageSelector)
    let prevButton = this.form.querySelector(fields.buttonBackPageSelector)
    nextButton.addEventListener('click', e => this.nextPage(e))
    prevButton.addEventListener('click', e => this.prevPage(e))
    this.toggleNaviButtons()
  }

  nextPage(e) {
    e.preventDefault()

    if (this.isFieldsetValid()) {
      let pages = [...this.form.querySelectorAll(fields.formPageSelector)]
      if (this.page <= pages.length) {
        this.page++

        pages.forEach((page, index) => {
          index + 1 === this.page
            ? page.classList.add(fields.errorShowClass)
            : page.classList.remove(fields.errorShowClass)
        })

        this.copyFieldsValues()

        this.scrollToTop()
        return this.toggleNaviButtons()
      }

      return this.toggleNaviButtons()
    }
  }

  prevPage(e) {
    e.preventDefault()

    let pages = [...this.form.querySelectorAll(fields.formPageSelector)]

    if (this.page > 1) {
      this.page--

      pages.forEach((page, index) => {
        index + 1 === this.page
          ? page.classList.add(fields.errorShowClass)
          : page.classList.remove(fields.errorShowClass)
      })

      this.scrollToTop()
      return this.toggleNaviButtons()
    }

    return this.toggleNaviButtons()
  }

  toggleNaviButtons() {
    let nextButton = this.form.querySelector(fields.buttonNextPageSelector)
    let prevButton = this.form.querySelector(fields.buttonBackPageSelector)
    let sendButton = this.form.querySelector(fields.buttonSend)
    let pages = [...this.form.querySelectorAll(fields.formPageSelector)].length

    if (this.page === 1) {
      prevButton.style.display = 'none'
      sendButton.style.display = 'none'
    }

    if (this.page > 1 && this.page < pages) {
      prevButton.style.display = 'block'
      nextButton.style.display = 'block'
      sendButton.style.display = 'none'
    }

    if (this.page === pages) {
      nextButton.style.display = 'none'
      sendButton.style.display = 'block'
    }
  }

  copyFieldsValues() {
    const activePageSelector = this.form.querySelector(
      `${fields.formPageSelector}.${fields.errorShowClass}`,
    )
    const dataValueFromSelectors = [
      ...activePageSelector.querySelectorAll('[data-value-from]'),
    ]
    dataValueFromSelectors.forEach(field => {
      if (!field.hasAttribute('dirty')) {
        let sourceInputName = field.getAttribute('data-value-from')
        let sourceInput = this.form.querySelector(`[name='${sourceInputName}']`)
        field.value = sourceInput.value
      }
    })
  }

  initFieldsetValidation(itemsToRefresh = []) {
    let allFieldsetElements =
      itemsToRefresh.length > 0 ? itemsToRefresh : [...this.form.elements]
    allFieldsetElements.forEach(element => {
      if (
        element.nodeName.toUpperCase() === 'INPUT' &&
        !element.classList.contains('do-not-validate')
      ) {
        let elementType = element.type.toUpperCase()

        if (elementType === 'TEXT') {
          element.addEventListener('input', e => this.testText(e.target))
        }

        if (elementType === 'NUMBER') {
          element.addEventListener('keydown', e => {
            let key = e.keyCode ? e.keyCode : e.which
            if (
              !(
                [8, 9, 13, 27, 46].indexOf(key) !== -1 ||
                (key == 65 && (e.ctrlKey || e.metaKey)) ||
                (key >= 35 && key <= 40) ||
                (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) ||
                (key >= 96 && key <= 105)
              )
            )
              e.preventDefault()
          })

          element.addEventListener('input', e => {
            if (e.target.value.length >= e.target.max.length) {
              e.target.value = e.target.value.slice(0, e.target.max.length)
            }
            this.testNumber(e.target)
          })
        }

        if (elementType === 'TEL') {
          element.addEventListener('input', e => this.testTel(e.target))
        }

        if (elementType === 'EMAIL') {
          element.addEventListener('input', e => this.testText(e.target))
        }

        if (elementType === 'CHECKBOX') {
          element.addEventListener('click', e => this.testCheckbox(e.target))
        }

        if (elementType === 'RADIO') {
          element.addEventListener('click', e => {
            this.testRadioElement(e.target)

            let conditionController = e.target.closest(
              '[data-condition-controller]',
            )

            if (conditionController) {
              let conditionData = JSON.parse(
                conditionController.getAttribute('data-condition-controller'),
              )
              let items = Object.getOwnPropertyNames(conditionData)

              items.map(key => {
                let target = conditionData[key]
                if (key === e.target.value) {
                  this.switchVisibility(target, 'show')
                } else {
                  this.switchVisibility(target, 'hide')
                }
              })
            }
          })
        }
      }

      if (
        element.nodeName.toUpperCase() === 'TEXTAREA' &&
        !element.classList.contains('do-not-validate')
      ) {
        element.addEventListener('input', e => {
          this.testText(e.target)
          this.testTextarea(e.target)
        })
      }

      if (
        element.nodeName.toUpperCase() === 'SELECT' &&
        !element.classList.contains('do-not-validate')
      ) {
        if (element.dataset.requiredif) {
          this.toggRequiredInput(element)
        }

        $(element)
          .selectric()
          .on('change', e => {
            this.testSelect(e.target)

            if (e.target.dataset.requiredif) {
              this.toggRequiredInput(e.target)
            }

            if (e.target.dataset.conditionController) {
              let affects = JSON.parse(e.target.dataset.conditionController)
              let targetId = affects.targetId
              let value = affects.value
              if (e.target.value === value) {
                this.switchVisibility(targetId, 'show')
              } else {
                this.switchVisibility(targetId, 'hide')
              }
            }
          })
      }
    })
  }

  isFieldsetValid() {
    let isFieldsetValid = true

    let allFieldsetElements = [
      ...this.form.querySelectorAll('fieldset')[this.page - 1].elements,
    ]

    let filteredAllFieldsetElements = allFieldsetElements.filter(
      element => !element.classList.contains('selectric-input'),
    )

    filteredAllFieldsetElements.forEach(element => {
      if (
        element.nodeName.toUpperCase() === 'INPUT' &&
        !element.classList.contains('do-not-validate')
      ) {
        let elementType = element.type.toUpperCase()

        if (elementType === 'TEXT') {
          if (!this.testText(element)) isFieldsetValid = false
        }

        if (elementType === 'NUMBER') {
          if (!this.testNumber(element)) isFieldsetValid = false
        }

        if (elementType === 'TEL') {
          if (!this.testTel(element)) isFieldsetValid = false
        }

        if (elementType === 'EMAIL') {
          if (!this.testText(element)) isFieldsetValid = false
        }

        if (elementType === 'CHECKBOX') {
          if (!this.testCheckbox(element)) isFieldsetValid = false
        }

        if (elementType === 'RADIO') {
          if (!this.testRadioElement(element)) isFieldsetValid = false
        }
      }

      if (
        element.nodeName.toUpperCase() === 'TEXTAREA' &&
        !element.classList.contains('do-not-validate')
      ) {
        if (!this.testText(element)) isFieldsetValid = false
      }

      if (
        element.nodeName.toUpperCase() === 'SELECT' &&
        !element.classList.contains('do-not-validate')
      ) {
        if (!this.testSelect(element)) isFieldsetValid = false
      }
    })
    return isFieldsetValid
  }

  toggRequiredInput(input) {
    let toggInput = this.form.querySelector(`input#${input.dataset.requiredif}`)
    let toggInputField = toggInput.parentElement
    let toggInputValue = input.dataset.requiredifValue

    if (input.value === toggInputValue) {
      toggInputField.style.display = 'block'
      return (toggInput.required = true)
    }

    toggInputField.style.display = 'none'
    return (toggInput.required = false)
  }

  scrollToTop() {
    let element = this.form.querySelector(`${fields.formPageSelector}`)
    element.scrollIntoView({ behavior: 'smooth' })
  }

  showValidatorMessage(input, errorText) {
    this.toggElementStatus(input, false, errorText)
    return false
  }

  getTranslation(messageKey) {
    return this.erroTextJson[messageKey]
  }

  testText(input) {
    let errorText = null
    let isValid = true
    let isInputRequired = input.required
    let isInputEmpty = input.value === '' ? true : false
    let inputValidator = input.dataset.validator
    let inputMinLength = input.getAttribute('minlength')

    if (isInputRequired || (!isInputRequired && !isInputEmpty)) {
      if (isInputEmpty) {
        errorText = this.getTranslation('validation.required.message')
        isValid = this.showValidatorMessage(input, errorText)
      } else {
        // Rule: Check minimum length of input
        if (inputMinLength) {
          if (validator.minLength(input)) {
            errorText = this.getTranslation('validation.minlength.message')
            isValid = this.showValidatorMessage(input, errorText)
          }
        }

        // Rule: Company Name
        if (inputValidator === 'companyName') {
          if (validator.companyName(input)) {
            errorText = this.getTranslation('validation.companyName.message')
            isValid = this.showValidatorMessage(input, errorText)
          }
        }

        // Rule: Simple String
        if (inputValidator === 'letters') {
          let mask = IMask(input, {
            mask: String,
          })

          if (mask.masked.value !== '') {
            if (validator.letters(input)) {
              errorText = this.getTranslation('validation.letters.message')
              isValid = this.showValidatorMessage(input, errorText)
            }
          }
        }

        // Rule: Numbers
        if (inputValidator === 'accountNumber') {
          if (validator.accountNumber(input)) {
            errorText = this.getTranslation('validation.accountNumber.message')
            isValid = this.showValidatorMessage(input, errorText)
          }
        }

        // Rule: Alphanumeric
        if (inputValidator === 'numbers') {
          if (validator.alphanumeric(input)) {
            errorText = this.getTranslation('validation.numbers.message')
            isValid = this.showValidatorMessage(input, errorText)
          }
        }

        // Rule: Full Name
        if (inputValidator === 'fullName') {
          let mask = IMask(input, {
            mask: String,
          })

          if (mask.masked.value !== '') {
            if (validator.fullNameValidator(input)) {
              errorText = this.getTranslation(
                'validation.containsFullName.message',
              )
              isValid = this.showValidatorMessage(input, errorText)
            }
          }
        }

        // Rule: Email
        if (inputValidator === 'email') {
          let mask = IMask(input, {
            mask: String,
          })

          if (mask.masked.value !== '') {
            if (validator.emailValidator(input)) {
              errorText = this.getTranslation(
                'validation.containsEmail.message',
              )
              isValid = this.showValidatorMessage(input, errorText)
            }
          }
        }

        // Rule: Year Between
        if (inputValidator === 'yearBetween') {
          let mask = IMask(input, {
            mask: '0000',
          })

          if (mask.masked.value !== '') {
            let yearFrom = 1900
            let yearTo = new Date().getFullYear()
            if (validator.yearBetweenValidator(input, yearFrom)) {
              isValid = false
              errorText = `Rok powstania musi znajdować się w przedziale ${yearFrom}-${yearTo}`
              this.toggElementStatus(input, false, errorText)
            }
          }
        }

        if (inputValidator === 'percentRange') {
          let mask = IMask(input, {
            mask: String,
          })

          if (mask.masked.value !== '') {
            let isInRange =
              parseFloat(input.value.trim()) >= 0 &&
              parseFloat(input.value.trim()) <= 100

            if (!isInRange) {
              errorText = this.getTranslation('validation.percentRange.message')
              isValid = this.showValidatorMessage(input, errorText)
            }
          }
        }

        // Rule: Post Code
        if (inputValidator === 'postCode') {
          let mask = IMask(input, {
            mask: '00-000',
          })

          if (mask.masked.value !== '') {
            if (validator.postCodeValidator(input)) {
              errorText = this.getTranslation(
                'validation.containsPostCode.message',
              )
              isValid = this.showValidatorMessage(input, errorText)
            }
          }
        }

        // Rule: Pesel
        if (inputValidator === 'pesel') {
          let mask = IMask(input, {
            mask: '00000000000',
          })

          if (mask.masked.value !== '') {
            if (validator.peselValidator(input)) {
              errorText = this.getTranslation('validation.pesel.message')
              isValid = this.showValidatorMessage(input, errorText)
            }
          }
        }

        // Rule: NIP
        if (inputValidator === 'nip') {
          let mask = IMask(input, {
            mask: '0000000000',
          })

          if (mask.masked.value !== '') {
            if (validator.nipValidator(input)) {
              errorText = this.getTranslation('validation.containsNip.message')
              isValid = this.showValidatorMessage(input, errorText)
            }
          }
        }

        // Rule KRS
        if (inputValidator === 'krs') {
          let mask = IMask(input, {
            mask: '0000000000',
          })

          if (mask.masked.value !== '') {
            if (validator.krsValidator(input)) {
              errorText = this.getTranslation('validation.containsKrs.message')
              isValid = this.showValidatorMessage(input, errorText)
            }
          }
        }

        // Home Address
        if (inputValidator === 'homeAddress') {
          if (validator.homeAddressValidator(input)) {
            errorText = this.getTranslation(
              'validation.containsHomeNumber.message',
            )
            isValid = this.showValidatorMessage(input, errorText)
          }
        }

        // Rule: REGON
        if (inputValidator === 'regon') {
          let mask = IMask(input, {
            mask: '00000000000000',
          })

          if (mask.masked.value !== '') {
            if (validator.regonValidator(input)) {
              errorText = this.getTranslation(
                'validation.containsRegon.message',
              )
              isValid = this.showValidatorMessage(input, errorText)
            }
          }
        }
      }
    }
    if (isValid) {
      this.toggElementStatus(input, true, errorText)
      return true
    }

    this.toggElementStatus(input, false, errorText)
    return false
  }

  testNumber(input) {
    let errorText = null
    let isValid = true
    let isInputRequired = input.required
    let isInputEmpty = input.value === '' ? true : false

    if (isInputRequired || (!isInputRequired && !isInputEmpty)) {
      if (isInputEmpty) {
        errorText = this.getTranslation('validation.required.message')
        isValid = this.showValidatorMessage(input, errorText)
      }
    }
    if (isValid) {
      this.toggElementStatus(input, true, errorText)
      return true
    }

    this.toggElementStatus(input, false, errorText)
    return false
  }

  testTel(input) {
    let errorText = null
    let isValid = true
    let isInputRequired = input.required
    let isInputEmpty = input.value === '' ? true : false

    if (isInputRequired || (!isInputRequired && !isInputEmpty)) {
      if (isInputEmpty) {
        errorText = this.getTranslation('validation.required.message')
        isValid = this.showValidatorMessage(input, errorText)
      } else {
        let mask = IMask(input, {
          mask: '000000000',
        })

        if (mask.masked.value !== '') {
          if (validator.phone(input)) {
            errorText = this.getTranslation(
              'validation.containsPhoneNumber.message',
            )
            isValid = this.showValidatorMessage(input, errorText)
          }
        }
      }
    }
    if (isValid) {
      this.toggElementStatus(input, true, errorText)
      return true
    }

    this.toggElementStatus(input, false, errorText)
    return false
  }

  testRadioElement(input) {
    let errorText = null
    let name = input.getAttribute('name')
    let group = input.form.querySelectorAll(
      'input[name="' + name + '"]:checked',
    )

    if (group.length) {
      errorText = ''
      this.toggElementStatus(input, true, errorText)
      return true
    } else {
      errorText = this.getTranslation('validation.required.message')
      this.toggElementStatus(input, false, errorText)
      return false
    }
  }

  testSelect(input) {
    let errorText = null
    let isValid = true

    if (
      input.value === '' ||
      input.value === 'noselected' ||
      input.value === '0'
    ) {
      isValid = false
      errorText = this.getTranslation('validation.required.message')
    }

    if (isValid) {
      this.toggElementStatus(input, true, errorText)
      return true
    }

    this.toggElementStatus(input, false, errorText)
    return false
  }

  testCheckbox(input) {
    let errorText = null
    let isInputRequired = input.required
    let isValid = true

    if (isInputRequired) {
      if (!input.checked) {
        isValid = false
        errorText = this.getTranslation('validation.check.statement.message')
      }
    }

    if (isValid) {
      this.toggElementStatus(input, true, errorText)
      return true
    } else {
      this.toggElementStatus(input, false, errorText)
      return false
    }
  }

  testTextarea(input) {
    let maxLengthLimit = input.getAttribute('maxlength')
    let regex = /(\r\n|\n|\r)/gm
    let lineBreaks = input.value.match(regex)
    if (lineBreaks !== null) {
      let lineBreaksCount = lineBreaks.length
      let currentTextAreaLength = input.value.length + lineBreaksCount * 3
      let newMaxLength =
        maxLengthLimit - (currentTextAreaLength - maxLengthLimit)
      if (currentTextAreaLength > maxLengthLimit) {
        return (input.value = input.value.substring(0, newMaxLength))
      }
    }
  }

  toggCheckboxElementStatus(input, isInputValid) {
    if (!isInputValid) {
      input.parentElement.classList.add(this.opts.erroCheckboxName)
      input.parentElement.querySelector(
        `.${this.opts.checkboxMessageName}`,
      ).innerHTML =
        this.opts.errorText['validation.check.statement.message'] || ''
    } else {
      input.parentElement.classList.remove(this.opts.erroCheckboxName)
    }
  }

  toggElementStatus(input, isInputValid, errorText) {
    let formField = input.closest(fields.formComponent)
    let formErroArea = formField.querySelector(fields.errorMessageSelector)

    if (isInputValid) {
      formField.classList.remove(fields.inputWithErrorClass)
      formErroArea.innerHTML = ''
    } else {
      formField.classList.add(fields.inputWithErrorClass)
      formErroArea.innerHTML = errorText
    }
  }

  switchVisibility(id, action) {
    let targetGroup = this.form.querySelector(`[data-condition-target='${id}']`)
    let targetGroupClassList = targetGroup.classList
    let targetFormElements = targetGroup.querySelectorAll(`input`)
    if (action === 'show') {
      targetGroupClassList.remove('hidden')
    } else {
      targetGroupClassList.add('hidden')
    }
    targetFormElements.forEach(element => {
      if (action === 'show') {
        element.disabled = false
        element.classList.remove('do-not-validate')
      } else {
        element.disabled = true
        element.classList.add('do-not-validate')
      }
    })
    this.initFieldsetValidation([...targetFormElements])
  }

  hideFormStatus() {
    let hideButton = this.form.querySelector(
      '.form__aplication__fundacja__state__link',
    )

    if (hideButton) {
      hideButton.addEventListener('click', e => {
        e.preventDefault()

        this.form.style.maxHeight = 'unset'

        let wrap = this.form.querySelector('.form__aplication__fundacja__state')
        let erro = this.form.querySelector(
          '.form__aplication__fundacja__state__erro',
        )

        wrap.classList.remove('form__aplication__fundacja__state--show')
        erro.classList.remove('form__aplication__fundacja__state__erro--show')
      })
    }
  }

  showLoading() {
    loader.show()
  }

  stopLoading() {
    loader.hide()
  }

  initSummaryButtons() {
    let summary = this.form.querySelector('.form-summary')
    let btnRetry = summary.querySelector('.button-retry')
    let btnHome = summary.querySelector('.button-home')
    btnRetry.addEventListener('click', () => {
      this.submitForm()
    })
    btnHome.addEventListener('click', () => {
      document.location.href = this.summaryHomeURL
    })
  }

  showSummary(resp) {
    let summary = this.form.querySelector('.form-summary')
    let head = summary.querySelector('h4')
    let desc = summary.querySelector('p')
    let btnRetry = summary.querySelector('.button-retry')
    let btnHome = summary.querySelector('.button-home')

    if (resp.success) {
      summary.classList.remove('error')
      summary.classList.add('show', 'success')
      btnHome.classList.add('show')
      btnRetry.classList.remove('show')
    } else {
      summary.classList.remove('success')
      summary.classList.add('show', 'error')
      btnHome.classList.remove('show')
      btnRetry.classList.add('show')
    }
    head.innerHTML = resp.data.header || ''
    desc.innerHTML = resp.data.message || ''
  }

  showBackendValidation(resp) {
    resp.forEach(erro => {
      let input = this.form.querySelector(`.${erro.field}`)

      this.toggElementStatus(input, false, erro.message)
    })
  }
}
