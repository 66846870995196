const lightbox = document.querySelector('.lightbox')

export const toggleLightbox = () => {
  lightbox.classList.toggle('active')
}

if (lightbox) {
  lightbox.addEventListener('click', function() {
    const activeElements = document.querySelectorAll('.active:not(.lightbox)')

    activeElements.forEach(element => {
      element.classList.remove('active')
    })

    toggleLightbox()
  })
}
