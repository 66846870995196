import Splide from '@splidejs/splide'
const elementName = '.widget-1.splide'
const elementSelector = document.querySelector(elementName)
if (elementSelector) {
  new Splide(elementName, {
    perPage: 1,
    type: 'loop',
    cover: true,
    height: '340px',
  }).mount()
}
